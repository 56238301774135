































// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import {
  FirebaseFirestore,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import "firebase/firestore";
import { FirebaseAuth } from "@firebase/auth-types";

@Component
export default class Friends extends Vue {
  readonly $appDB!: FirebaseFirestore;
  readonly $appAuth!: FirebaseAuth;
  private uid = "";
  private userlist: any[] = [];
  private usernames: any[] = [];
  private friendid: any[] = [];
  private myfriends: any[] = [];
  private saveFriend: any[] = [];
  private selectFriend: any[] = [];

  mounted(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    this.$appDB
      .collection(`users/profiles/data`) // Sort by category name
      .onSnapshot((qs: QuerySnapshot) => {
        this.userlist.splice(0); // remove old data
        qs.forEach((qds: QueryDocumentSnapshot) => {
          if (qds.exists) {
            const catData = qds.data();
            this.userlist.push({
              uid: catData.uid,
              name: catData.username,
              games: catData.gameCount,
              friendlist: catData.friendList,
            });
          }
        });
        this.filterFriends();
      });
  }

  filterFriends(): void {
    for (var i = 0; i < this.userlist.length; i++) {
      if (this.userlist[i].uid == this.uid) {
        if (typeof this.userlist[i].friendlist !== "undefined") {
          for (var f = 0; f < this.userlist[i].friendlist.length; f++) {
            this.myfriends[i] = this.userlist[i].friendlist[f];
          }
        }
      }
    }
    for (var k = 0; k < this.userlist.length; k++) {
      for (var j = 0; j < this.myfriends.length; j++) {
        if (this.userlist[k].uid == this.myfriends[j]) {
          this.myfriends[j] = this.userlist[k];
        }
      }
    }
    for (var l = 0; l < this.userlist.length; l++) {
      this.usernames.push(this.userlist[l].name);
    }
  }

  saveNames(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    for (var i = 0; i < this.userlist.length; i++) {
      for (var j = 0; j < this.saveFriend.length; j++) {
        if (this.saveFriend[j] == this.userlist[i].name) {
          this.myfriends.push(this.userlist[i]);
          this.friendid.push(this.userlist[i].uid);
        }
      }
    }
    this.$appDB
      .collection("users/profiles/data")
      .doc(this.uid)
      .update({
        friendList: this.friendid,
      });
  }

  deleteFriends(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    for (var i = 0; i < this.myfriends.length; i++) {
      for (var j = 0; j < this.selectFriend.length; j++) {
        if (this.selectFriend[j].name == this.myfriends[i].name) {
          this.friendid.splice(i, 1);
        }
      }
    }
    this.myfriends = [];
    for (var k = 0; k < this.friendid.length; k++) {
      for (var m = 0; m < this.userlist.length; m++) {
        if (this.friendid[k] == this.userlist[m].uid) {
          this.myfriends.push(this.userlist[m]);
        }
      }
    }
    this.$appDB
      .collection("users/profiles/data")
      .doc(this.uid)
      .update({
        friendList: this.friendid,
      });
  }

  data() {
    return {
      headers: [
        {
          text: "Username",
          value: "name",
        },
        {
          text: "Games Played",
          value: "games",
        },
      ],
    };
  }
}
