








// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import NavComponent from "../components/Nav.vue"
import AboutComponent from "../components/AboutCard.vue"

@Component({
  components: {
    NavComponent,
    AboutComponent
  },
})
export default class AboutView extends Vue {}
