






























// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import {
  FirebaseFirestore,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "@firebase/firestore-types";
import "firebase/firestore";
import { FirebaseAuth } from "@firebase/auth-types";

@Component
export default class PinballSave extends Vue {
  readonly $appDB!: FirebaseFirestore;
  readonly $appAuth!: FirebaseAuth;
  private uid = "none";
  private rank = "";
  private score = 0;
  private dateTime = new Date().toLocaleString();
  private currGameCount = 0;
  private valid = true;
  private username = "";

  private scores: any[] = [];

  mounted(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";

    this.$appDB
      .collection(`users/profiles/data/`)
      .onSnapshot((qs: QuerySnapshot) => {
        this.scores.splice(0); // remove old data
        qs.forEach((qds: QueryDocumentSnapshot) => {
          // console.log("qds.exists: " + qds.exists);
          // console.log("qds.data().scores: " + qds.data().scores);
          // console.log("qds.data().uid: " + qds.data().uid);
          if (
            qds.exists &&
            qds.data().scores != undefined &&
            qds.data().uid == this.uid
          ) {
            // console.log(qds.data().scores);
            qds.data().scores.forEach((el: any) => {
              this.scores.push(el);
              // console.log("printing scores: " + el);
            });
          }

          if(qds.exists && qds.data().username != undefined){
            this.username = qds.data().username;
          }
        });
      });
  }

  saveToDb(): void {
    this.$appDB
      .collection("users/profiles/data") // Sort by category name
      .doc(this.uid)
      .get()
      .then((doc) => {
        this.currGameCount = doc.data()?.gameCount;
        this.currGameCount += 1;
      });

    this.$appDB.collection("games/pinball/scores").add({
      uid: this.uid,
      dateTime: this.dateTime,
      rank: this.rank,
      score: this.score,
      username: this.username
    });

    this.scores.push(this.score);

    this.$appDB
      .collection("users/profiles/data/")
      .doc(this.uid)
      .update({
        gameCount: this.currGameCount,
      });

    this.$appDB
      .collection(`users/profiles/data`)
      .doc(this.uid)
      .update({
        scores: this.scores,
      });
  }
}
