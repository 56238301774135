



















// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import {
  FirebaseFirestore,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import "firebase/firestore";
import { FirebaseAuth } from "@firebase/auth-types";

@Component
export default class YourScores extends Vue {
  readonly $appDB!: FirebaseFirestore;
  readonly $appAuth!: FirebaseAuth;
  private scores: any[] = [];
  private uid = "";
  private myScores: any[] = [];


  userLoggedIn(): boolean {
    return this.$appAuth.currentUser?.uid !== undefined;
  }

  mounted(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    this.$appDB
      .collection(`users/profiles/data/`)
      .onSnapshot((qs: QuerySnapshot) => {
        this.scores.splice(0); // remove old data
        qs.forEach((qds: QueryDocumentSnapshot) => {
          if (
            qds.exists &&
            qds.data().scores != undefined &&
            qds.data().uid == this.uid
          ) {
            qds.data().scores.forEach((el: any) => {
              this.scores.push(el);
            });
          }
        });
        // console.log(this.scores)
      });
  }

  filterScores(): void {
    for (var i = 0; i < this.scores.length; i++) {
      if (this.scores[i].uid == this.uid) {
        this.myScores.push({
          uid: this.scores[i].uid,
          rank: this.scores[i].rank,
          score: this.scores[i].score,
          dateTime: this.scores[i].dateTime,
        });
      }
    }
  }

  data() {
    return {
      headers: [
        { text: "Date", value: "dateTime" },
        { text: "Points", value: "score" },
      ],
    };
  }
}
