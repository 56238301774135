



































































// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";

@Component
export default class AboutComponent extends Vue {
  message(): void {
    console.log("Button click routes to game");
  }
}
