














// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import NavComponent from "../components/Nav.vue";
import PinballSave from "../components/PinballSave.vue";
import PinballGame from "../components/PinballGame.vue";

@Component({
  components: {
    NavComponent,
    PinballSave,
    PinballGame,
  },
})
export default class PinballGameView extends Vue {}
