





























// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import {
  FirebaseFirestore,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import "firebase/firestore";
import { FirebaseAuth } from "@firebase/auth-types";

@Component
export default class AboutUser extends Vue {
  readonly $appDB!: FirebaseFirestore;
  readonly $appAuth!: FirebaseAuth;
  private openEdit = false;
  private newName = "";
  private name = "";
  private uid = "none";

  private userRules = [
    (value: string | any[]) =>
      (value && value.length >= 3) || "Min 3 characters",
  ];

  mounted(): void {
    this.uid = this.$appAuth.currentUser?.uid ?? "none";
    this.$appDB
      .collection(`users/profiles/data`) // Sort by category name
      .doc(this.uid)
      .get()
      .then((doc) => {
        if (doc.data()?.username === undefined) {
          this.$appDB
            .collection(`users/profiles/data`)
            .doc(this.uid)
            .update({
              username: "Set Username",
            });
            this.name = doc.data()?.username;
        } else {
          this.name = doc.data()?.username;
        }
      });
  }

  saveName(): void {
    this.$appDB
      .collection("users/profiles/data")
      .doc(this.uid)
      .update({
        username: this.newName,
      });
    this.name = this.newName;
  }
}
