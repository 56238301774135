


















// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import NavComponent from "../components/Nav.vue";
import Highscore from "../components/Highscores.vue";
import YourScores from "../components/YoursScores.vue";
import AboutUser from "../components/AboutUser.vue";
import Friends from "../components/Friends.vue";

@Component({
  components: {
    NavComponent,
    Highscore,
    YourScores,
    AboutUser,
    Friends,
  },
})
export default class ScoresView extends Vue {}
