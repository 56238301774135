<template>
  <div id="PinballGameComponent">
    <unity
      src="https://simmercdn.com/unity/8hqXnXgzG5Vf7mJlAE8ncuXCKtA2/content/1b2697e7-aaed-a87a-66e1-255fa7470d4f/Build/data.json?t=1619598461276"
      width="1000"
      height="600"
      unityLoader="https://simmercdn.com/unity/8hqXnXgzG5Vf7mJlAE8ncuXCKtA2/content/1b2697e7-aaed-a87a-66e1-255fa7470d4f/Build/UnityLoader.js?t=1619598461276"
      ref="myInstance"
      id="pinballGame"
    ></unity>
  </div>
</template>

<script>
// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import Unity from "vue-unity-webgl";

@Component({
  components: {
    Unity,
  },
})
export default class PinballGameComponent extends Vue {}
</script>

<style>
.fullscreen {
  display: none;
}
</style>
