







// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import NavComponent from "../components/Nav.vue"

@Component({
  components: {
    NavComponent
  },
})
export default class PlanetDefenderGame extends Vue {}
