










// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import {
  FirebaseFirestore,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from "@firebase/firestore-types";
import "firebase/firestore";
import { FirebaseAuth } from "@firebase/auth-types";

@Component
export default class HighScores extends Vue {
  readonly $appDB!: FirebaseFirestore;
  readonly $appAuth!: FirebaseAuth;
  private scores: any[] = [];
  private users: any[] = [];

  mounted(): void {
    this.$appDB
      .collection(`users/profiles/data`) // Sort by category name
      .onSnapshot((qs: QuerySnapshot) => {
        this.users.splice(0); // remove old data
        qs.forEach((qds: QueryDocumentSnapshot) => {
          if (qds.exists) {
            const catData = qds.data();
            this.users.push({
              uid: catData.uid,
              name: catData.username,
            });
          }
        });
      });

    this.$appDB
      .collection(`games/pinball/scores`)
      .orderBy("rank") // Sort by category name
      .onSnapshot((qs: QuerySnapshot) => {
        this.scores.splice(0); // remove old data
        qs.forEach((qds: QueryDocumentSnapshot) => {
          if (qds.exists) {
            const catData = qds.data();
            const newName = this.getUsername(catData.uid);
            this.scores.push({
              uid: catData.uid,
              rank: catData.rank,
              score: catData.score,
              username: newName,
            });
          }
        });
      });
  }

  getUsername(uid: string) {
    for (var j = 0; j < this.users.length; j++) {
      if (uid == this.users[j].uid) {
        return this.users[j].name;
      }
    }
  }

  data() {
    return {
      headers: [
        {
          text: "Username",
          value: "username",
        },
        { text: "Points", value: "score" },
      ],
    };
  }
}
